<script>
import { Modal } from "bootstrap";
import Layout from "../../layouts/main.vue";
import { getMuclegroups,postMusclegroup,removeMusclegroup } from "@/services/admin.service";
import PageHeader from "@/components/page-header";
import appConfig from "../../../app.config";
import { useAuth0 } from "@auth0/auth0-vue";

export default {
    musclegroupobj: "",
    setup() {
      const { getAccessTokenSilently } = useAuth0();
      return{
        getAccessTokenSilently
      }
    },
  page: {
    title: "Starter",
    meta: [{ name: "description", content: appConfig.description }],
  },
  methods: {
    deleteModal(item, index) {
        this.musclegroup = item;
        this.removeid = index;
        this.myRemoveModal = new Modal(document.getElementById('deleteModal'), {})
        this.myRemoveModal.show()
    },
    showModal(){
        this.myAddModal = new Modal(document.getElementById('showModal'), {})
        this.myAddModal.show()
    },
    async getData(){
          //var result = false;
          const { getAccessTokenSilently } = useAuth0();
          const accessToken = await getAccessTokenSilently();
          const { data, error } = await getMuclegroups(accessToken);
          if (data) {
            this.musclegroups = data;
          }
          if (error) {
            this.musclegroups = [];
          }
      },
      async addMusclegroup(){
        this.musclename = this.$refs.musclename.value
        this.svgname = this.$refs.svglabel.value
        this.svgid = this.$refs.svgid.value
        const postdata = JSON.stringify({
            musclename: this.musclename,
            svglabel: this.svgname,
            svgid: this.svgid
        })
        console.log("Posting: " + postdata);
        const accessToken = await this.getAccessTokenSilently();
        const { data, error } = await postMusclegroup(accessToken,postdata);
        if (data) {
            console.log(data);
            this.myAddModal.hide()
        }
        if (error) {
            console.log(error);
        }
      },
      async removeMusclegroup(musclegroup,index){
        this.muscleid = this.$refs.muscleid.value
        console.log("removing: " + musclegroup + "at index: " + index);
        const deletedata = JSON.stringify({
            muscleid: this.muscleid,
        })
        console.log("Removing: " + deletedata);
        const accessToken = await this.getAccessTokenSilently();
        const { data, error } = await removeMusclegroup(accessToken,deletedata);
        if (data) {
            console.log(data);
            this.myRemoveModal.hide()
            this.musclegroups.splice(index,1);
            
        }
        if (error) {
            console.log(error);
        }
      },
  },
  mounted() {
    this.getData();
  },
  data() {
    return {
      myAddModal: null,
      myRemoveModal: null,
      musclename: '',
      svglabel: '',
      svgid: '',
      muscleid: '',
      removeid: '',
      musclegroup: '',
      musclegroups: Array,
      title: "Muscle groups",
      items: [
        {
          text: "Admin",
          href: "/",
        },
        {
          text: "Muscle groups",
          active: true,
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
     <!-- start page title -->
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title mb-0">{{ $t("t-musclegrouptableheading") }}</h4>
                </div><!-- end card header -->

                <div class="card-body">
                    <div id="customerList">
                        <div class="row g-4 mb-3">
                            <div class="col-sm-auto">
                                <div>
                                    <button type="button" class="btn btn-info add-btn" @click="showModal()" id="create-btn"><i class="ri-add-line align-bottom me-1"></i> {{ $t("t-Add") }}</button>
                                </div>
                            </div>
                            <div class="col-sm">
                                <div class="d-flex justify-content-sm-end">
                                    <div class="search-box ms-2">
                                        <input type="text" class="form-control search" placeholder="Search...">
                                        <i class="ri-search-line search-icon"></i>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="table-responsive table-card mt-3 mb-1">
                           
                            <table class="table align-middle table-nowrap" id="customerTable">
                                <thead class="table-light">
                                    <tr>
                                        <th scope="col" style="width: 50px;">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" id="checkAll" value="option">
                                            </div>
                                        </th>
                                        <th class="sort" data-sort="id">{{ $t("t-Id") }}</th>
                                        <th class="sort" data-sort="name">{{ $t("t-Name") }}</th>
                                        <th class="sort" data-sort="svglabel">{{ $t("t-SVGLabel") }}</th>
                                        <th class="sort" data-sort="svgid">{{ $t("t-SVGGroupId") }}</th>
                                        <th class="sort" data-sort="created">{{ $t("t-CreatedAt") }}</th>
                                        <th class="sort" data-sort="action">{{ $t("t-Action") }}</th>
                                    </tr>
                                </thead>
                                <tbody class="list form-check-all">
                                    <tr scope="row" v-for="(musclegroup, index) in musclegroups.result" :key="index">
                                        <th scope="row">
                                            <div class="form-check">
                                                <input class="form-check-input" type="checkbox" name="chk_child" value="option1">
                                            </div>
                                        </th>
                                        <td>{{musclegroup._id}}</td>
                                        <td>{{musclegroup.muscle}}</td>
                                        <td>{{musclegroup.svgGroupLabel}}</td>
                                        <td>{{musclegroup.svgGroupId}}</td>
                                        <td>{{musclegroup.createdAt}}</td>
                                        <td>
                                            <div class="d-flex gap-2">
                                                <div class="remove">
                                                    <button class="btn btn-sm btn-danger remove-item-btn" @click="deleteModal(musclegroup, index)">{{ $t("t-Remove") }}</button>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="noresult" style="display: none">
                                <div class="text-center">
                                    <h5 class="mt-2">{{ $t("t-SorryNoresultsfound") }}</h5>
                                    <p class="text-muted mb-0">{{ $t("t-Wecouldntfindanyting") }}</p>
                                </div>
                            </div>
                        </div>

                        <div class="d-flex justify-content-end">
                            <div class="pagination-wrap hstack gap-2">
                                <a class="page-item pagination-prev disabled" href="#">
                                    {{ $t("t-Previous") }}
                                </a>
                                <ul class="pagination listjs-pagination mb-0"></ul>
                                <a class="page-item pagination-next" href="#">
                                    {{ $t("t-Next") }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div><!-- end card -->
            </div>
            <!-- end col -->
        </div>
        <!-- end col -->
    </div>
    <!-- end row -->
    <div class="modal fade" id="showModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header bg-light p-3">
                    <h5 class="modal-title" id="exampleModalLabel"></h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal"></button>
                </div>
                <form>
                    <div class="modal-body">
                        <div class="mb-3">
                            <label for="musclenamefield" class="form-label">{{ $t("t-Musclename") }}</label>
                            <input type="text" ref="musclename" id="musclenamefield" class="form-control" placeholder="Enter Name" required />
                        </div>

                        <div class="mb-3">
                            <label for="svglabel-field" class="form-label">{{ $t("t-SVGLabel") }}</label>
                            <input type="text" ref="svglabel" id="svglabel-field" class="form-control" placeholder="Enter SVG name" required />
                        </div>

                        <div class="mb-3">
                            <label for="svgid-field" class="form-label">{{ $t("t-SVGGroupId") }}</label>
                            <input type="text" ref="svgid" id="svgid-field" class="form-control" placeholder="Enter SVG ID" required />
                        </div>
                    </div>
                    <div class="modal-footer">
                        <div class="hstack gap-2 justify-content-end">
                            <button type="button" class="btn btn-light" data-bs-dismiss="modal">{{ $t("t-Close") }}</button>
                            <button type="submit" class="btn btn-success" @click.prevent="addMusclegroup" id="add-btn">{{ $t("t-AddMusclegroup") }}</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <!-- Modal -->
    <div class="modal fade zoomIn deleteModal" id="deleteModal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="btn-close"></button>
                </div>
                <div class="modal-body">
                    <div class="mt-2 text-center">
                        <input type="hidden" ref="muscleid" id="muscleid" :value="musclegroup._id"/>
                        <div class="mt-4 pt-2 fs-15 mx-4 mx-sm-6">
                            <h4>{{ $t("t-AreYouSure") }}</h4>
                            <p class="text-muted mx-4 mb-0">{{ $t("t-Areyousureyouwanttoremovethismuclegroup") }}</p>
                            <p>{{musclegroup.muscle}}</p>
                        </div>
                    </div>
                    <div class="d-flex gap-2 justify-content-center mt-4 mb-2">
                        <button type="button" class="btn w-sm btn-light" data-bs-dismiss="modal">{{ $t("t-Close") }}</button>
                        <button type="submit" class="btn w-sm btn-danger " @click.prevent="removeMusclegroup(musclegroup,removeid)" id="delete-record">{{ $t("t-YesDeleteit") }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--end modal -->
    </Layout>
</template>